.review_page {
    height: calc(100vh - 90px);
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
}

.review_left_column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.review_right_column {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.alerts_page {
    height: calc(100vh - 70px);
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.alerts_page::-webkit-scrollbar {
    display: none;
  }

.alert_page {
    width: 90%;
    margin-left: 5%;
}

.process_container {
    flex: 1;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    overflow-y: scroll;
}

.cards_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    grid-auto-rows: auto;
}

.processes_clear_cotainer {
    width: 80%;
    margin-left: 10%;
    text-align: center;
    color: var(--text-color)
}

.activities_container {
    width: 100%;
    margin-top: 5px;
}

.activities_buttons_container {
    height: 40px;
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    display: grid;
    grid-template-columns: auto 100px;
}

.activities_buttons_list {
    display:flex;
}

.form_title {
    margin-bottom: 20px;
    color: var(--text-color);
}

.activity_container {
    flex: 1;
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    background-color: rgb(235, 235, 235);
}

.activity_header {
    width: 100%;
}

.activity_header_title {
    display: inline-block;
    text-align: right;
    padding-right: 5px;
    vertical-align:middle
}

.activity_header_icon {
    display: inline-block;
    text-align: left;
    padding-left: 5px;
    vertical-align:middle
}

.activity_title > h2 {
    margin-top: 0px;
}

.activity_viewer {
    width: 100%;
    height: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.alert_page_container {
    height: calc(100vh - 70px);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

/* .alert_page_container::-webkit-scrollbar {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  } */

.test_alert {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;

}

.test_alert_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.test_alert_header {
    flex:1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.test_alert_header_step_title {
    font-weight: bold;
    font-size: 20px;
    color: var(--text-color);
}

.test_alert_header_text {
    font-size: 18px;
    margin-left: 20px;
    color: var(--text-color);
}

.test_alert_archived_by {
    margin-left: 20px;
    margin-top: -2px;
}

.test_alert_text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    color: var(--text-color);
}

.test_alert_text_key {
    font-weight: bold;
}

.test_alert_id {
    font-size: 15px;
    height: 30px;
    line-height: 30px;
}

.test_alert_button {
    margin: auto;
    display: flex;
    flex-direction: row;
}

.alerts_top_cotainer {
    height: 60px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.alerts_clear_text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.5em;
    color: var(--text-color);
}

.alerts_clear_gif {
    margin: 20px;
}

.alerts_gif {
    border-radius: 10px;
}

.alerts_buttons_row{
    padding: 10px;
    display: inline-block;
}

.alerts_container {
    flex: 1;
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    margin-top: 15px;
    overflow-y: scroll;
}

.report_button_container {
  margin-top: 10px;
  width: 100%;
  text-align: right;
}

.alert_container {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

.report_ {
  width: 40%;
  min-height: 40%;
  margin-left: 30%;
  margin-top: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  border-radius: 20px;
}

.report_modal_container {
    width: 40%;
    min-height: 20%;
    margin-left: 30%;
    margin-top: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--modal-background-color);
    border-radius: 20px;
  }

.report_modal_form {
  width: 60%;
  margin-left: 20%;
}

.report_modal_title {
  text-align: center;
  color: var(--text-color)
}

.report_explanation {
  width: 60%;
  margin-left: 20%;
  color: var(--text-color)
}

@media screen and (max-width: 1650px){ 
    .alerts_page {
        width: 96%;
        margin-left: 2%;
    }

    .review_page {
        width: 96%;
        margin-left: 2%;
    }
}
.login_page {
    height: 100vh; 
    width: 100vw;
    padding-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_header {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_icon {
    height: 40px;
    border-radius: 10px;
    margin-right: 10px;
}

.login_title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 2em;
    font-weight: normal;
    color: var(--text-color);
}

.login_container {
    width: 400px;
    padding: 20px;
    border: 3px solid lightgray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login_text_container {
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    font-weight: normal;
    color: var(--text-color);
}

.login_textfield_container{
    width: 80%;
    margin-top: 5px;
    margin-bottom: 5px;
}

.login_button_container {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 5px;
}

.login_link {
    text-decoration: none;
    color: #9c27b0;
}
.activities_page {
  height: calc(100vh - 70px);
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.activity_editor_modal_container {
  width: 40%;
  min-height: 40%;
  margin-left: 30%;
  margin-top: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--modal-background-color);
  border-radius: 20px;
}

.activity_editor_title {
  text-align: center;
  color: var(--text-color);
}


.activity_editor_form {
  width: 60%;
  margin-left: 20%;
}

.activity_editor_form_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.activity_editor_form_input {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.activity_editor_buttons_row{
  margin: 20px;
  margin-right: 100px;
  display: flex;
  flex-direction: row-reverse;
}

.alert_container {
  position: absolute;
  left: 20px;
  bottom: 20px;
}

@media screen and (max-width: 1650px){ 
  .activities_page {
      width: 96%;
      margin-left: 2%;
  }
}
.tests_page {
    width: 80%;
    height: calc(100vh - 70px);
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.new_test_modal_container {
    width: 40%;
    min-height: 5%;
    margin-left: 30%;
    margin-top: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--modal-background-color);
    border-radius: 20px;
}

.new_test_title {
    text-align: center;
    color: var(--text-color)
}

.new_test_form {
    width: 60%;
    margin-left: 20%;
}

.new_test_form_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.new_test_form_input {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.new_test_buttons_row{
    margin: 20px;
    margin-right: 100px;
    display: flex;
    flex-direction: row-reverse;
}

.status_flag {
    padding: 5px;
    background-color: bisque;
    border-radius: 5px;
    cursor: pointer;
}

.status_flag_ok {
    background-color: var(--status-flag-ok-background-color);
}

.status_flag_alert {
    background-color: var(--status-flag-alert-background-color);
}

.status_flag_fail {
    background-color: red;
    color: white;
}

.status_flag_waiting {
    background-color: var(--status-flag-waiting-background-color);
}

.execution_buttons_container {
    width: 90%;
    height: 100px;
    margin-left: 5%;
    display: flex;
}
  
.execution_button {
    margin-left: 10px;
}

.test_log_modal_container {
    width: 40%;
    min-height: 40%;
    margin-left: 30%;
    margin-top: 10%;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: var(--modal-background-color);
    border-radius: 20px;
}

.test_log_modal_title {
    text-align: center;
    color: var(--text-color);
}

.test_log_modal_text {
    width: 60%;
    margin-left: 20%;
    margin-top: 30px;
    color: var(--text-color);
}

@media screen and (max-width: 1650px){ 
    .tests_page {
        width: 96%;
        margin-left: 2%;
    }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

:root {
  --background-color: white;
  --modal-background-color: white;
  --text-color: black;
  --actions-row-background-color: #F0E8F1;
  --actions-row-selected-filter: #E2D2E4;
  --status-flag-ok-background-color: lightgreen;
  --status-flag-alert-background-color: lightsalmon;
  --status-flag-waiting-background-color: lightblue;
  --test-editor-activity-background-color: #F0E8F1;
  --history-column-background-color: #F0E8F1;
  --history-column-color: black;
}

:root[data-theme="dark"] {
  --background-color: #181818;
  --modal-background-color: #212121;
  --text-color: white;
  --actions-row-background-color: #6D2077;
  --actions-row-selected-filter: #8A4D92;
  --status-flag-ok-background-color: green;
  --status-flag-alert-background-color: salmon;
  --status-flag-waiting-background-color: blue;
  --test-editor-activity-background-color: #6D2077;
  --history-column-background-color: #6D2077;
  --history-column-color: white;
}
.users_page {
  height: calc(100vh - 70px);
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.new_user_modal_container {
  width: 40%;
  min-height: 5%;
  margin-left: 30%;
  margin-top: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--modal-background-color);
  border-radius: 20px;
}

.new_user_title {
  text-align: center;
  color: var(--text-color);
}


.new_user_form {
  width: 60%;
  margin-left: 20%;
}

.new_user_form_row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}

.new_user_form_input {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.new_user_buttons_row{
  margin: 20px;
  margin-right: 100px;
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1650px){ 
  .users_page {
      width: 96%;
      margin-left: 2%;
  }
}
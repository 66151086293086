.test_page {
    height: calc(100vh - 70px);
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.test_top_cotainer {
    height: 70px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.test_buttons_row {
    padding: 10px;
    display: inline-block;
}

.test_container {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
}

.test_section_title {
    color: var(--text-color);
}

.test_tab_container {
    height: calc(100vh - 150px);
    overflow-y: scroll;
}

.test_tab_container::-webkit-scrollbar {
    display: none;
}

.test_title {
    text-align: center;
    margin-top: -30px;
    margin-bottom: 0px;
}

.test_editor_form {
    width: 80%;
    margin-left: 10%;
}

.test_editor_form_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.test_editor_form_input {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.test_editor_buttons_row{
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 10px;
    background-color: white;
    z-index: 50;
    display: flex;
    flex-direction: row-reverse;
}

.test_editor_form_step {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.test_editor_form_step_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.test_editor_form_title {
    flex:1;
    display: flex;
    flex-direction: row;
    color: var(--text-color);
}

.test_editor_add_step {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    color: var(--text-color)
}

.test_editor_add_step_buttons_row {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.change_image {
    width: 98%;
    margin-top: 10px;
    border: 3px solid violet;
}

.alert_container {
    position: absolute;
    left: 20px;
    bottom: 20px;
}

.test_editor_activities_container {
    width: 100%;
}

.test_editor_activity {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 15px;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
    display: flex;
    color: var(--text-color);
}

.test_editor_activity_search {
    background-color: var(--test-editor-activity-background-color);
}

.test_editor_activity_title {
    text-align: left;
    flex: 1;
    display: flex;
    align-items: center;
}

.test_editor_activity_buttons {
    text-align: right;
    margin: 0px;
    padding: 0px;
    flex: 1;
}

.log_container {
    width: calc(80% - 40px);
    margin-left: 10%;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 10px;
}

.app_editor_move_step_buttons_row {
    flex:1;
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
}

.status_card_container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-rows: auto;
    grid-gap: 5px;
}

.log_card_container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    grid-gap: 5px;
}

@media screen and (max-width: 1650px){ 
    .test_page {
        width: 96%;
        margin-left: 2%;
    }
}
.App {
  height: 100vh;
  text-align: center;
  background-color: var(--background-color);
}

.header {
  height: 70px;
  width: 80%;
  margin-left: 10%;
  display: flex;
  align-items: center;
}

.header_left_container {
  flex:2;
  display: flex;
  align-items: center;
}

.header_right_container {
  flex:1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.header_icon {
  height: 30px;
  border-radius: 10px;
}

.header_title {
  margin: 0px;
  margin-left: 10px;
  margin-right: 40px;
  font-size: 30px;
  color: var(--text-color);
}

.header_logo {
  height: 50px;
  margin-top: 7px;
  margin-left: 0px;
}

.header_crumbs {
  font-size: 30px;
  font-weight: 300;
  color: var(--text-color);
}

.header_avatar {
  margin-left: 20px;
  cursor: pointer;
}

/* Global */

.actions_row {
  height: 50px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: var(--actions-row-background-color);
  border-radius: 25px;
  overflow: hidden;
}

.actions_row_left_container {
  flex:1;
  display: flex;
  align-items: center;
}

.actions_row_right_container {
  flex:1;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.search_box_container {
  width: 300px;
  margin-right: 20px;
}

.fab_container {
  position: absolute;
  bottom: 50px;
  right: 100px;
}

@media screen and (max-width: 1650px){ 
  .header {
      width: 90%;
      margin-left: 5%;
  }
}
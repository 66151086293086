.user_page {
    height: calc(100vh - 70px);
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.user_top_container {
    height: 70px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
}

.user_buttons_row {
    padding: 10px;
    display: inline-block;
}

.user_container {
    flex: 1;
    width: 100%;
}

.user_title {
    color: var(--text-color)
}

.user_form {
    width: 80%;
    margin-left: 10%;
}

.user_form_row {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
}

.user_form_input {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
}

.users_list {
    height: 80vh;
}

@media screen and (max-width: 1650px){ 
    .user_page {
        width: 96%;
        margin-left: 2%;
    }
}
.history_page {
  height: calc(100vh - 70px);
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

.history_value_container:hover {
  cursor: pointer;
}

.history_viewer_modal_container {
  width: 40%;
  min-height: 20%;
  margin-left: 30%;
  margin-top: 10%;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: var(--modal-background-color);
  border-radius: 20px;
}

.history_viewer_modal_title {
    text-align: center;
    color: var(--text-color);
}

.history_viewer_modal_comparator {
    width: 60%;
    margin-left: 20%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.history_viewer_modal_column {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    background-color: var(--history-column-background-color);
    color: var(--history-column-color);
}

@media screen and (max-width: 1650px){ 
  .history_page {
      width: 96%;
      margin-left: 2%;
  }
}
.variables_page {
  height: calc(100vh - 70px);
  width: 80%;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1650px){ 
  .variables_page {
      width: 96%;
      margin-left: 2%;
  }
}